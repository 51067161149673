.btn-agis-link {
    -webkit-appearance: none;
    background: #fff;
    border: 0;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    cursor: pointer;
    display: inline-block;
    height: 36px;
    margin: 0;
    min-width: 36px;
    outline: 0;
    overflow: hidden;
    text-decoration: none;
    text-overflow: ellipsis;
    -webkit-transition: background-color .2s,border .2s,-webkit-box-shadow .2s;
    transition: background-color .2s,border .2s,-webkit-box-shadow .2s;
    transition: background-color .2s,border .2s,box-shadow .2s;
    transition: background-color .2s,border .2s,box-shadow .2s,-webkit-box-shadow .2s;
    vertical-align: middle;
    white-space: nowrap;
    border-radius: 4px;
    color: #1a73e8;
    border: 1px solid #dadce0;
    -webkit-box-shadow: none;
    box-shadow: none;
    font: 400 16px/24px Roboto,Noto Sans,Noto Sans JP,Noto Sans KR,Noto Naskh Arabic,Noto Sans Thai,Noto Sans Hebrew,Noto Sans Bengali,sans-serif;
    line-height: 34px;
    padding: 0 24px;
}

.btn-agis-link:hover {
    border: 1px solid #d2e3fc;
    background: #e8f0fe;
}