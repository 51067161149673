.a-agis-block {
    display: inline-block;

    padding: .25rem .5rem;
    
    height: 40px;
    min-width: 100px;

    text-align: center;
    vertical-align: middle;

    color: #fff;
    font-size: 14px;
    line-height: 30px;
    white-space: nowrap;
    
    background-color: #4184b4;
    border-radius: 0px;
    box-shadow: 0 1px 1px rgba(0,0,0,.15);  
}

.a-agis-block:hover {
    color: #fff;
    background-color: #528FBA;
    text-decoration: none;
}

.a-agis-block:active {
    background-color: #6199C0; 
}



.a-agis-light-outline {
    display: inline-block;

    padding: .25rem .5rem;

    height: 40px;
    min-width: 100px;

    text-align: center;
    vertical-align: middle;
    
    color: #868e96; 
    font-size: 14px;
    line-height: 30px;
    white-space: nowrap;
    
    background-color: transparent;
    border: 0px;
    border-radius: 20px;  
}

.a-agis-light-outline:hover {
    color: #464a4e;
    text-decoration: none;
    background-color: #f4f4f4;
}

.a-agis-light-outline:active {
    color: #464a4e;
    background-color: #ebebeb;
}

.nav-agis-light-outline {
    display: inline-block;

    padding: .25rem .5rem;

    height: 40px;
    min-width: 100px;

    text-align: center;
    vertical-align: middle;
    
    color: #868e96; 
    font-size: 14px;
    line-height: 30px;
    white-space: nowrap;
    
    background-color: transparent;
    border: 0px;
}

.nav-agis-light-outline:hover {
    color: #464a4e;
    text-decoration: none;
}

/* .nav-agis-light-outline:active {
    color: #464a4e;
} */

.nav-agis-light-outline.active {
    font-weight: 400;
    color: #4f81bc;
    border-bottom: 3px solid #4f81bc;
}