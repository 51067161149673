.question-link {
    cursor: pointer;
    border: 0px solid rgba(0,0,0,.125);
    color: #54585a;
    border-radius: 3px;
}

.question-link:hover {
    background-color: #e9ecef;
    text-decoration: none;
    color: #54585a;
    
    box-shadow: 0 4px 4px 0 rgba(0,0,0,0.07), 0 3px 7px 0 rgba(0,0,0,0.06);
}