.position-component {
    cursor: pointer;
    color: #54585a;
}

.position-component:hover {
    background-color: #e9ecef;
    text-decoration: none;
    color: #54585a;
}
