.card-agis {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;

    padding-top: .0rem;
    padding-bottom: .0rem;
    border: 1px solid #ebebeb;
    border-radius: 0px;
    box-shadow: 0 1px 1px rgba(0,0,0,.15);

    font-size: 14px;
    padding: .3rem .35rem;
    min-height: 40px;
}

.card-agis.clickable {
    text-decoration: none;
    color: #54585a;
    cursor: pointer;
    color: #54585a;
}

.card-agis.clickable:hover {
    background-color: #e9ecef;
    text-decoration: none;
    color: #54585a;
}