.btn-agis-primary {
    -webkit-appearance: none;
    background: #fff;
    border: 0;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    cursor: pointer;
    display: inline-block;
    height: 36px;
    margin: 0;
    min-width: 36px;
    outline: 0;
    overflow: hidden;
    text-decoration: none;
    text-overflow: ellipsis;
    -webkit-transition: background-color .2s,border .2s,-webkit-box-shadow .2s;
    transition: background-color .2s,border .2s,-webkit-box-shadow .2s;
    transition: background-color .2s,border .2s,box-shadow .2s;
    transition: background-color .2s,border .2s,box-shadow .2s,-webkit-box-shadow .2s;
    vertical-align: middle;
    white-space: nowrap;
    border-radius: 4px;
    color: #1a73e8;
    border: 1px solid #dadce0;
    -webkit-box-shadow: none;
    box-shadow: none;
    font-family:"Google Sans", "Noto Sans", "Noto Sans JP", "Noto Sans KR", "Noto Naskh Arabic", "Noto Sans Thai", "Noto Sans Hebrew", "Noto Sans Bengali", sans-serif;
    font-size:14px;
    font-stretch:100%;
    font-style:normal;
    font-variant-caps:normal;
    font-variant-east-asian:normal;
    font-variant-ligatures:normal;
    font-variant-numeric:normal;
    font-weight:500;
    padding: 0 24px;

    color: #fff;
    background: #041e42;
    line-height: 36px;
}

.btn-agis-primary:hover {
    -webkit-box-shadow: 0 1px 2px 0 rgba(60,64,67,.3), 0 1px 3px 1px rgba(60,64,67,.15);
    box-shadow: 0 1px 2px 0 rgba(60,64,67,.3), 0 1px 3px 1px rgba(60,64,67,.15);
    background: #041e42;
    color: #fff;
}