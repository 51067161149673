.question-button {
    user-select: none;
    border: 1px solid transparent;
    cursor: pointer;
    font-size: 14px;
    font-weight: 400;
    max-width: 200px;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: center;
    text-decoration: none;
    touch-action: manipulation;
    white-space: nowrap;

    height: 24px;
    border-radius: 3px;
    color: #666;
    background-color: #ebebeb;
}

.question-button.link {
    padding-left: 0px;
    padding-right: 0px;
    color: #007bff;
    background-color: transparent;
} 

.question-button.link:hover {
    color: #0056b3;
} 