.agis-warning {
    background-color: #f9edbe;
    border: 1px solid #f0c36d;
    -webkit-border-radius: 0 0 2px 2px;
    border-radius: 0 0 2px 2px;
    -webkit-box-shadow: 0 2px 4px rgba(0,0,0,0.2);
    box-shadow: 0 2px 4px rgba(0,0,0,0.2);
    color: #222;
    padding: 6px 10px;
    font-size: 12px;
    font-weight: 600;
}

.agis-error {
    background-color: #f8d7da;
    border: 1px solid #f5c6cb;
    -webkit-border-radius: 0 0 2px 2px;
    border-radius: 0 0 2px 2px;
    -webkit-box-shadow: 0 2px 4px rgba(0,0,0,0.2);
    box-shadow: 0 2px 4px rgba(0,0,0,0.2);
    color: #721c24;
    padding: 6px 10px;
    font-size: 12px;
    font-weight: 600;
}