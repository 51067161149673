.table-agis {
    width: 100%;
    max-width: 100%;
    background-color: transparent;
    font-size: "12px";
    border: 1px solid #e9ecef;
    /* user-select: none; */
}

.table-agis tr {
    height: 44px;
}

.table-agis tbody tr:hover {
    color: #212529;
    background-color: rgba(0,0,0,.075);
}

.table-agis th {
    border: 1px solid #e9ecef;
    /*border-bottom: 2px solid #e9ecef;*/
    text-align: center!important;
    vertical-align: middle!important;
}

.table-agis td {
    padding: .75rem;
    padding-top: .1rem!important;
    padding-bottom: .1rem!important;
    text-align: center!important;
    vertical-align: middle!important;
    border: 1px solid #e9ecef;
}

