.main-menu {
    z-index: 2;
    background-color: white;
    /* box-shadow: 0 1px 1px rgba(0,0,0,.15); */
    /* box-shadow: 0 1px 6px 0 rgba(32,33,36,0.28); */
    border-bottom: 1px solid #E6E6E6;
}

.main-search {
    height: 32px;
    width: 360px;
    background-color: #f2f2f2;
}

.expand-menu {
    z-index: 300;

    position: fixed;
    top: 50px;
    left: 0px;

    height: calc(100% - 50px);
    width: 100%;

    overflow: auto;

    padding-top: 10px;
    padding-bottom: 10px;

    background-color: #fff;
}

.second-menu {
    z-index: 2;
    
    background-color: white;
    /* box-shadow: 0 1px 1px rgba(0,0,0,.15); */
}

.second-menu-top {
    z-index: 2;

    top: 50px;
    height: 40px;
    line-height: 40px;
    /* box-shadow: 0 1px 6px 0 rgba(32,33,36,0.28); */
}