.btn-agis-light {
    background-attachment:scroll;
    background-clip:border-box;
    background-color:rgb(245, 246, 247);
    border-bottom-color:#ebebeb;
    border-bottom-left-radius:4px;
    border-bottom-right-radius:4px;
    border-bottom-style:solid;
    border-bottom-width:1px;
    border-image-outset:0px;
    border-image-repeat:stretch;
    border-image-slice:100%;
    border-image-source:none;
    border-image-width:1;
    border-left-color:#ebebeb;
    border-left-style:solid;
    border-left-width:1px;
    border-right-color:#ebebeb;
    border-right-style:solid;
    border-right-width:1px;
    border-top-color:#ebebeb;
    border-top-left-radius:4px;
    border-top-right-radius:4px;
    border-top-style:solid;
    border-top-width:1px;
    box-shadow:none;
    box-sizing:border-box;
    color:rgb(60, 64, 67);
    cursor:pointer;
    font-family:"Google Sans", "Noto Sans", "Noto Sans JP", "Noto Sans KR", "Noto Naskh Arabic", "Noto Sans Thai", "Noto Sans Hebrew", "Noto Sans Bengali", sans-serif;
    font-size:14px;
    font-stretch:100%;
    font-style:normal;
    font-variant-caps:normal;
    font-variant-east-asian:normal;
    font-variant-ligatures:normal;
    font-variant-numeric:normal;
    font-weight:500;
    height:36px;
    letter-spacing:normal;
    line-height:36px;
    outline-color:rgb(60, 64, 67);
    outline-style:none;
    outline-width:0px;
    overflow-x:hidden;
    overflow-y:hidden;
    padding: 0 24px;
    text-align:center;
    text-decoration-color:rgb(60, 64, 67);
    text-decoration-line:none;
    text-decoration-style:solid;
    text-indent:0.01px;
    text-overflow:ellipsis;
    text-rendering:auto;
    text-shadow:none;
    text-size-adjust:100%;
    text-transform:none;
    transition-delay:0s;
    transition-duration:0.2s;
    transition-property:background-color;
    transition-timing-function:ease;
    vertical-align:middle;
    white-space:nowrap;
    word-spacing:0px;
    writing-mode:horizontal-tb;
    -webkit-appearance:none;
    -webkit-box-direction:normal;
    -webkit-font-smoothing:antialiased;
    -webkit-border-image:none;
}

.btn-agis-light:hover {
    background-color:rgb(249, 250, 251);
}

.btn-agis-light:active {
    background-color:rgb(246, 246, 248);
}