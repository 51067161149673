.question-input {
    width: 100%;
    padding: .5rem .5rem;
    color: #464a4e;
    font-size: 14px;
    font-weight: 600;
    line-height: 18px;

    background-color: #fbfbfb;
    border-radius: 6px;
    border: 1px solid #ebebeb;
}

.question-input::placeholder {
    color: #9aa1a9;
    font-weight: 300;
}

.question-input:disabled {
    color: #6a6e73;
    background-color: #efefef;
}

.transparent-input {
    width: 100%;
    color: #464a4e;
    font-size: 14px;
    line-height: 18px;

    background-color: transparent;
    border-radius: 0px;
    border: 0px;
}

.transparent-input.underline {
    border-bottom: 2px solid #eee;
}

.transparent-input::placeholder {
    color: #9aa1a9;
    font-weight: 300;
}